import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
// import Banking from './Banking';
const Industries = () => {
    // const location = useLocation();
    // const normalizedPath = location.pathname.replace(/\/$/, "");

    return (
        <>
            { <Outlet />}
        </>
    )
}

export default Industries