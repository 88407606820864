
import React from 'react';
import ButtonComponent from "../../../Components/Buttons/ButtonComponent";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const Arrow = `${digitalOceanURL}/itarrow.svg`
const bg_image = `${digitalOceanURL}/About_Us_img/About_bg.png`;
const home_img = `${digitalOceanURL}/Industry/Sector/IT_home_img.png`;

const ItHeroSection = () => {
  return (
    <div >
      <div className="relative  background_color ">
        {/* Image that is hidden on small screens */}
        <img
          src={bg_image}
          alt="hero img it page"
          title='about-us-bg for it page'
          className="absolute  object-cover w-full lg:h-[150%] h-[110%] md:h-[120%] bg_curve_img"
        />
        <div className="mb-16 relative  w-full h-full md:top-28 lg:top-0">
          <div className="grid grid-cols-12 gap-4 justify-items-center relative top-0 lg:top-32 lg:mx-20 xl:mx-32 2xl:mx-40 items-center ">

            {/* Text container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-7 w-full Banking_heading font-semibold font-[poppins] top_spacing">
              <span className="industry_info">IT/ITES</span>

              <h1 className="w-full text-[32px] md:text-[40px] lg:text-[46px] sector_head">
                <span className="lg:flex"> Verification Solutions <img loading="lazy" src={Arrow} alt="Arrow img it page" className="arrow_img" /></span>for IT/ITES Workforce and Vendors</h1>
              <h2 className="about-text-para mt-2"> Enhance trust and security through streamlined verification processes for IT/ITES management. </h2>

              {/* Button Group */}
              <div className="mt-4">
                <ButtonComponent arial-label="Get in touch with an expert for Verification Solutions for IT/ITES Workforce and Vendors" />
              </div>
            </div>

            {/* Image container */}
            <div className="col-span-12 md:col-span-12 lg:col-span-5 relative   lg:top-4 bottom_space mb-20 lg:mb-0">
              <img src={home_img} title='home-img in it page' alt="about-girl it page" className="w-full h-auto object-cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItHeroSection;
