import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LazyImage from "../../../LazyImg/LazyImage";
const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const Industry_Background = `${digitalOceanURL}/Industry/industry-background.png`;
const Industry_1 = `${digitalOceanURL}/Industry/industry_1.png`;
const Industry_2 = `${digitalOceanURL}/Industry/industry_2.png`;
const Industry_3 = `${digitalOceanURL}/Industry/industry_3.png`;
const Industry_4 = `${digitalOceanURL}/Industry/industry_4.png`;
const Industry_5 = `${digitalOceanURL}/Industry/industry_5.png`;
const Industry_6 = `${digitalOceanURL}/Industry/industry_6.png`;
const Industry_7 = `${digitalOceanURL}/Industry/industry_7.png`;
const Industry_8 = `${digitalOceanURL}/Industry/industry_8.png`;
const Industry_9 = `${digitalOceanURL}/Industry/industry_9.png`;
const Industry_10 = `${digitalOceanURL}/Industry/industry_10.png`;
const Industry_11 = `${digitalOceanURL}/Industry/industry_11.png`;
const IndustrySection = () => {

  // const [width, setWidth] = useState(window.innerWidth); // Initial width from the window

  // useEffect(() => {
  //   // Function to update the width
  //   const handleResize = () => {
  //     setWidth(window.innerWidth);
  //   };

  //   // Add event listener for window resize
  //   window.addEventListener('resize', handleResize);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  // console.log('industry section');

  return (
    <div className=" mt-32  lg:h-96 " id="industry">

      <div className="hidden lg:block">

        {/* <img
          loading="lazy"
          src={Industry_Background}
          title="background for industry section"
          alt="industry_background section in home page"
          className="ml-5 absolute mx-12 industry-bg-img "
        /> */}

        <LazyImage
          src={Industry_Background}
          Title="background for industry section"
          alt="industry_background section in home page"
          className="ml-5 absolute mx-12 industry-bg-img "
        />


        <div className="grid grid-cols-3 lg:grid-cols-8 lg:mx-12 justify-center items-center flex-center-children  mx-2.5">
          <div className="col-span-2 custom-sm-span-2 lg:col-span-4 flex flex-col order-1 sm:order-1 lg:order-5 ml-16 lg:ml-0 ">
            <div className="slider-heading-container text-center sm:text-center lg:text-left relative  right-10 sm:mb-8 industry_text_Section">
              {/* <h2 className=" font-semibold mb-6  md:text-[44px] lg:text-[48px] section_headings">
                Pick Your <span className="text-[#1580C2]">Industry</span>
              </h2> */}
              <h2 className=" inline font-semibold mb-6  md:text-[44px] lg:text-[48px] section_headings">
                Pick Your {" "}
              </h2>
              <span className=" font-semibold mb-6  md:text-[44px] text-[#1580C2] lg:text-[48px] section_headings">
                Industry
              </span>
              {/* ---------------------- */}
              <p className="font-[poppins] text-[16px] mt-2 industry-text">
                Explore how Hirestar.io empowers 500+ <br />companies secure screening and due diligence
              </p>
            </div>
          </div>

          <div className="relative order-2 sm:order-3 lg:order-1 lg:mt-8 md:m-6 flex flex-col items-center lg:top-8 industry_child_Section1">
            <Link to="industries/it-ites" className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 lg:-ml-4">
              {/* <img loading="lazy" src={Industry_1} title="it/ites industry" alt="it/ites industry section icon" className="industry-img-2 object-contain"
              // width="75" 
              // height="75"
              /> */}
              <LazyImage
                src={Industry_1}
                Title="it/ites industry"
                alt="it/ites industry section icon"
                className="industry-img-2 object-contain"
              />

              <div>
                <h3 className="industry-texts text-center mt-4">IT / ITeS</h3>
              </div>
            </Link>
          </div>


          <div className="relative order-3 sm:order-4 lg:order-2 lg:top-8 flex md:m-6 industry_child_Section2">
            <Link
              to="/industries/banking"
              className="flex flex-col cursor-pointer items-center transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_2}
                  alt="finance industry section icon"
                  title="finance industry"
                  className="transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                  width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_2}
                  alt="finance industry section icon"
                  Title="finance industry"
                  className="transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                />

              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Finance</h3>
              </div>
            </Link>
          </div>

          <div className="relative order-4 sm:order-5 lg:order-3 lg:top-8 md:m-6 industry_child_Section3">
            <Link
              to="/industries/healthcare"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 "

            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_3}
                  title="healthcare industry"
                  alt="medical industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                  width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_3}
                  Title="healthcare industry"
                  alt="medical industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                />

              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Medical</h3>
              </div>
            </Link>
          </div>
          <div className="relative order-5 sm:order-6 lg:order-4 lg:top-8 lg:left-2 md:m-6 industry_child_Section4 industry_child_Section8">
            <Link
              to={"/industries/logistics"}
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_4}
                  title="logistics industry"
                  alt="logistics industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                  width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_4}
                  Title="logistics industry"
                  alt="logistics industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Logistics</h3>
              </div>
            </Link>
          </div>

          <div className="relative order-6 sm:order-7 lg:order-5 lg:top-16 flex md:m-6 industry_child_Section1 industry_child_Section8">
            <Link
              to="/industries/ecommerce"
              className="flex flex-col cursor-pointer items-center transform transition-transform duration-300 ease-in-out hover:scale-110 lg:-ml-4"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_5}
                  alt="ecommerce industry section icon"
                  title="ecommerce industry"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                  width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_5}
                  alt="ecommerce industry section icon"
                  Title="ecommerce industry"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">E-commerce</h3>
              </div>
            </Link>
          </div>
          <div className="relative order-7 sm:order-8 lg:order-6 lg:top-16 md:m-6 industry_child_Section2 industry_child_Section8">
            <Link
              to="/industries/education"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_6}
                  title="education industry"
                  alt="education industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                  width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_6}
                  Title="education industry"
                  alt="education industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Education</h3>
              </div>
            </Link>
          </div>
          <div className="relative order-8 sm:order-9 lg:order-7 lg:top-16  md:m-6 industry_child_Section3 industry_child_Section9">
            <Link
              to="/industries/online-gaming"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_7}
                  alt="online-gaming industry section icon"
                  title="online-gaming industry"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                  width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_7}
                  alt="online-gaming industry section icon"
                  Title="online-gaming industry"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                />

              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Games</h3>
              </div>
            </Link>
          </div>

          <div className="relative order-9 sm:order-10 lg:order-8 lg:top-16 lg:left-2 md:m-6 industry_child_Section4 industry_child_Section9 industry_child_Section16">
            <Link
              to="/industries/travel"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_8}
                  title="travel industry"
                  alt="travel industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                  width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_8}
                  Title="travel industry"
                  alt="travel industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Travel</h3>
              </div>
            </Link>
          </div>
          <div className="relative order-10 sm:order-13 lg:order-11 lg:top-16 lg:left-2 md:m-6 industry_child_Section5 industry_child_Section9">
            <Link
              to="/industries/manufacturing"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_9}
                  title="manufacturing industry"
                  alt="manufacturing industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                  width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_9}
                  Title="manufacturing industry"
                  alt="manufacturing industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"

                />


              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Manufacturing</h3>
              </div>
            </Link>
          </div>
          <div className="relative order-11 sm:order-13 lg:order-11 lg:top-16 lg:left-3 md:m-6 industry_child_Section6 industry_child_Section10 ">
            <Link
              to="/industries/gig-workers"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 lg:ml-4"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_10}
                  title="gig workers industry"
                  alt="gig workers industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                  width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_10}
                  Title="gig workers industry"
                  alt="gig workers industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                />
              </div>

              <div className="mt-4">
                <h3 className="industry-texts text-center text-nowrap">Gig Workers</h3>
              </div>
            </Link>
          </div>
          <div className="relative order-12 sm:order-13 lg:order-11 lg:top-16 lg:left-7 md:m-6 industry_child_Section6 industry_child_Section9">
            <Link
              to="/industries/consumer-goods"
              className="flex flex-col cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 lg:ml-4"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_11}
                  title="consumer goods industry"
                  alt="consumer goods industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                  width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_11}
                  Title="consumer goods industry"
                  alt="consumer goods industry section icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"

                />
              </div>
              <div className="mt-4 text-center">
                <h3 className="industry-texts">FMCG</h3>
              </div>
            </Link>
          </div>
        </div>

      </div>
      {/* --------------------------------md and small screen------------------------------------------------------------- */}
      <div className="lg:hidden text-center">
        {/* <h2 className=" font-semibold mb-2  md:text-[44px] lg:text-[48px] section_headings">
          Pick Your <span className="text-[#1580C2]">Industry</span>
        </h2> */}
        <h2 className=" inline font-semibold mb-6  md:text-[44px] lg:text-[48px] section_headings">
          Pick Your {" "}
        </h2>
        <span className=" font-semibold mb-6  md:text-[44px] text-[#1580C2] lg:text-[48px] section_headings">
          Industry
        </span>
        <p className="font-[poppins] text-[16px] mt-1 industry-text">
          Explore how Hirestar.io empowers 500+ <br />companies secure screening and due diligence
        </p>
        {/* --------------------------------- */}
        <div className="grid grid-cols-3 justify-center items-center mx-2 gap-8 mt-16">

          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link to="industries/it-ites" className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110" >
              {/* <img src={Industry_1} alt="it-industry md-icon" className="industry-img-2 object-contain" width='75'
                height='75' title="Hirestar-Industry-Image-1" /> */}
              <LazyImage
                src={Industry_1}
                Title="Hirestar-Industry-Image-1"
                alt="it-industry md-icon"
                className="industry-img-2 object-contain"
              />
              <div>
                <h3 className="industry-texts text-center mt-4">IT / ITeS</h3>
              </div>
            </Link>
          </div>


          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/banking"
              className="flex flex-col cursor-pointer items-center transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_2}
                  alt="banking industry md-icon"
                  title="Hirestar-Industry-Image-2"
                  className=" transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain" width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_2}
                  alt="banking industry md-icon"
                  Title="Hirestar-Industry-Image-2"
                  className=" transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Finance</h3>
              </div>
            </Link>
          </div>

          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/healthcare"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                {/* <img loading="lazy"
                  title="Hirestar-Industry-Image-3"
                  src={Industry_3}
                  alt="healthcare industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain" width='75'
                  height='75'
                /> */}
                <LazyImage
                  Title="Hirestar-Industry-Image-3"
                  src={Industry_3}
                  alt="healthcare industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"

                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Medical</h3>
              </div>
            </Link>
          </div>
          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to={"/industries/logistics"}
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_4}
                  title="Hirestar-Industry-Image-4"
                  alt="logistics industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain" width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_4}
                  Title="Hirestar-Industry-Image-4"
                  alt="logistics industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Logistics</h3>
              </div>
            </Link>
          </div>

          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/ecommerce"
              className="flex flex-col cursor-pointer items-center transform transition-transform duration-300 ease-in-out hover:scale-110 lg:-ml-4"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_5}
                  title="Hirestar-Industry-Image-5"
                  alt="ecommerce industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain" width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_5}
                  Title="Hirestar-Industry-Image-5"
                  alt="ecommerce industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"

                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">E-commerce</h3>
              </div>
            </Link>
          </div>
          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/education"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_6}
                  title="Hirestar-Industry-Image-6"
                  alt="education industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain" width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_6}
                  Title="Hirestar-Industry-Image-6"
                  alt="education industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"

                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Education</h3>
              </div>
            </Link>
          </div>
          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/online-gaming"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_7}
                  title="Hirestar-Industry-Image-7"
                  alt="gaming industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain" width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_7}
                  Title="Hirestar-Industry-Image-7"
                  alt="gaming industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"

                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Games</h3>
              </div>
            </Link>
          </div>

          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/travel"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain" width='75'
              height='75'
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_8}
                  title="Hirestar-Industry-Image-8"
                  alt="travel industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain" width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_8}
                  Title="Hirestar-Industry-Image-8"
                  alt="travel industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"

                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Travel</h3>
              </div>
            </Link>
          </div>
          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/consumer-goods"
              className="flex flex-col cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 lg:ml-4 object-contain" width='75'
              height='75'
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_11}
                  title="Hirestar-Industry-Image-11"
                  alt="consumer goods industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain" width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_11}
                  Title="Hirestar-Industry-Image-11"
                  alt="consumer goods industry md-icon"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                />
              </div>
              <div className="mt-4 text-center">
                <h3 className="industry-texts">FMCG</h3>
              </div>
            </Link>
          </div>
          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/gig-workers"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 lg:ml-4 object-contain" width='75'
              height='75'
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_10}
                  alt="gig workers industry md-icon"
                  title="Hirestar-Industry-Image-10"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain" width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_10}
                  alt="gig workers industry md-icon"
                  Title="Hirestar-Industry-Image-10"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"

                />
              </div>

              <div className="mt-4">
                <h3 className="industry-texts text-center ">Gig Workers</h3>
              </div>
            </Link>
          </div>


          <div className="relative  md:m-6 flex flex-col items-center ">
            <Link
              to="/industries/manufacturing"
              className="flex flex-col items-center cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
            >
              <div>
                {/* <img loading="lazy"
                  src={Industry_9}
                  title="Hirestar-Industry-Image-9"
                  alt="Industry_10"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain" width='75'
                  height='75'
                /> */}
                <LazyImage
                  src={Industry_9}
                  Title="Hirestar-Industry-Image-9"
                  alt="Industry_10"
                  className="industry-img-2 transform transition-transform duration-300 ease-in-out hover:scale-110 object-contain"
                />
              </div>
              <div className="mt-4">
                <h3 className="industry-texts text-center">Manufacturing</h3>
              </div>
            </Link>
          </div>

        </div>


      </div>



    </div>
  );
};

export default IndustrySection;
