
import React, { useState } from "react";
import ScrollMenuContainer from "./components/ScrollableMenu/ScrollMenuContainer";
import ServiceCard from "./Services/ServiceCard";
import LazyLoad from "react-lazy-load";
import {
  descreetInterviewData,
  BlueCollarService,
  WhiteCollarService,
  GigWorkerService,
  MoonLightingService,
  SocialMediaService,
  DrugTestService
} from "./Services/Service";
import LazyImage from "../../../LazyImg/LazyImage";

const digitalOceanURL =
  "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";
const service_bg = `${digitalOceanURL}/serviec-image/service.png`;

const menuItems = [
  { id: 1, serviceName: "White Collar", service: WhiteCollarService },
  { id: 2, serviceName: "Blue Collar", service: BlueCollarService },
  { id: 3, serviceName: "Gig Workers", service: GigWorkerService },
  { id: 4, serviceName: "Moonlighting", service: MoonLightingService },
  { id: 5, serviceName: "Social Media", service: SocialMediaService },
  { id: 6, serviceName: "Discreet Interview", service: descreetInterviewData },
  { id: 7, serviceName: "Drug Check", service: DrugTestService }
];

const OurServiceSection = () => {
  const [activeIndex, setActiveIndex] = useState(0); // Default to first service
  const [visibleCardsCount, setVisibleCardsCount] = useState(6); // Show 6 cards initially

  const handleServiceClickIndex = (index) => {
    setActiveIndex(index);
    setVisibleCardsCount(6); // Reset to 6 cards when switching services
  };

  const showMoreCards = () => {
    setVisibleCardsCount((prevCount) => prevCount + 6); // Show 6 more cards on click
  };

  const currentService = menuItems[activeIndex].service; // Get current service's card data
  const visibleCards = currentService.slice(0, visibleCardsCount); // Slice cards to display

  return (
    <>
      <div className="mt-32 relative mx-2">
        {/* Ensure background image is positioned correctly without blocking the content */}
        {/* <img
          loading="lazy"
          src={service_bg}
          alt="service_background our services section"
          className="absolute top-0 left-0 w-full h-full z-0 object-cover"
        /> */}
        <LazyImage
          src={service_bg}
          alt="service_background our services section"
          className="absolute top-0 left-0 w-full h-full z-0 object-cover"
        />

        {/* ScrollMenuContainer to switch services */}
        <LazyLoad offset={0} once={true}>
          <ScrollMenuContainer
            handleServiceClickIndex={handleServiceClickIndex}
            activeIndex={activeIndex}
            menuItems={menuItems}
          />
        </LazyLoad>

        {/* Show the cards dynamically */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-center relative mt-16 lg:mx-36 ">
          {visibleCards?.map((card, index) => (
            <ServiceCard card={card} index={index} />
          ))}
        </div>
      </div>
      {/* --------------show more button------------------ */}
      {visibleCardsCount < currentService.length && (
        <div className="text-center">
          <button
            onClick={showMoreCards}
            className="rounded-full px-4 py-2 view_all text-white w-[20%] lg:w-[13%] mt-8"
          >
            Show More
          </button>
        </div>
      )}
    </>
  );
};

export default OurServiceSection;
