import HelpCard from "../../Components/Footer/HelpCenterCard/HelpCard";
import FaqComponent from "../../Components/Footer/FaqComponent/FaqComponent";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import OurClients from "../../Components/OurClientSection/OurClients";
import TestimonialComponent from "../../Components/Testimonial/TestimonialComponent";
import PickIndustryComponent from "./PickIndustryComponent";

const digitalOceanURL =
    "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";
const bg_image = `${digitalOceanURL}/About_Us_img/About_bg.png`;
const indHeroImg = `${digitalOceanURL}/HelpCardAssets/allIndustries.png`;
const AllIndustries = () => {
    return (
        <>
            <div className="relative  background_color  ">
                {/* Image that is hidden on small screens */}
                {/* if breaks add object-cover in the given img */}
                <img
                    src={bg_image}
                    alt="hero img for banking sector"
                    className="absolute   w-full lg:h-[150%] h-[110%] md:h-[120%] bg_curve_img"
                />

                <div className="mb-16 relative  w-full h-full md:top-28 lg:top-0 ">
                    <div className="grid grid-cols-12 gap-4 justify-items-center relative top-0 lg:top-32 lg:mx-20 xl:mx-32 2xl:mx-40 items-center ">
                        {/* Text container */}
                        <div className="col-span-12 md:col-span-12 lg:col-span-7  Banking_heading font-semibold font-[poppins]  top_spacing mx-2 lg:mx-0">
                            {/* <span className="industry_info">
                                Banking and Financial Services
                            </span> */}
                            <h1 className="w-full text-[32px] md:text-[40px] lg:text-[46px]">
                                Your Trusted
                            </h1>
                            <p className="w-full text-[32px] md:text-[40px] lg:text-[46px]">
                                Verification Platform
                            </p>
                            <p className="w-full text-[32px] md:text-[40px] lg:text-[46px]">
                                Across Industries
                            </p>
                            {/* h2 heading */}
                            <h2 className="about-text-para mt-2 ">
                                Enhance your operations with solutions that comply with
                                regulations and foster trust throughout your digital onboarding
                                and verification processes.
                            </h2>

                            {/* Button Group */}
                            <div className="mt-4">
                                <ButtonComponent arialLabel={"Get in touch with an expert for automated background checks in Banking sector"} />
                            </div>
                        </div>

                        {/* Image container */}
                        <div className="col-span-12 md:col-span-12 lg:col-span-5 relative mx-2   lg:-top-8 bottom_space lg:mb-0 mb-20">
                            <img
                                src={indHeroImg}
                                alt="about-girl banking sector"
                                className="w-full h-auto object-cover"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="lg:mt-72 mt-44">
                <OurClients />
            </div>

            <PickIndustryComponent />
            <TestimonialComponent />

            <FaqComponent />
            <div className="relative top-28  left-0 right-0">
                <HelpCard />
            </div>
        </>
    )
}

export default AllIndustries