import BlogsPage from "../BlogsPage/BlogsPage"
import { Outlet, useLocation } from "react-router-dom"
const BlogOutlet = () => {
    const location = useLocation();
    return (
        <>
            {location.pathname === "/blogs" || location.pathname === "/blogs/" ? <BlogsPage /> : <Outlet />}
        </>
    )
}

export default BlogOutlet