import { useState, useEffect } from "react";
import LazyImage from "../../../LazyImg/LazyImage";
const digitalOceanURL =
    "https://hirestarorg.blr1.digitaloceanspaces.com/Hirestar-Landing-Page/assets";

const img1 = `${digitalOceanURL}/HomeImages/img1.png`;
const img2 = `${digitalOceanURL}/HomeImages/img2.png`;
const img3 = `${digitalOceanURL}/HomeImages/img3.png`;
const img4 = `${digitalOceanURL}/HomeImages/img4.png`;

const images = [
    img1,
    img2,
    img3,
    img4
];
const HomePageCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    console.log("homepage hero");
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);
        return () => clearInterval(timer);
    }, []);

    return (
        <div className="relative w-full max-w-sm mx-auto small-image">
            <div className="overflow-hidden rounded-lg">
                <div
                    className="flex transition-transform duration-300 ease-in-out"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {images.map((src, index) => (
                        <img
                            key={index}
                            src={src}
                            alt={`Slide ${index + 1} showing hiring benefits`}
                            className="w-full h-auto flex-shrink-0"
                            title={`Hirestar-carousel-Image-${index + 1}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HomePageCarousel